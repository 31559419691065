import { useState, useEffect, useCallback } from "react";
import '../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'

function AccountProof(props){

    const [state,setState] = useState(null);
    //preview image before submission
    const [preview, setPreview] = useState()
    //setting defendant into a state
    const [defendant,setDefendant] = useState('');
    //preserve query parameters on submission
    const {search} = useLocation();
    //navigate to success on submission
    const navigate = useNavigate();
    //display placeholder image for pdf
    const [isPdf, setIsPdf] = useState(false);
    //Error on incorrect file format
    const [wrongFile, setwrongFile]  = useState(false);

    //defendant name based on URL parameter
    const getDefendantName = useCallback(() => {
        let defendant = props.urlParams["defendant"]
        if(defendant){
            setDefendant(defendant)
        } else {
            navigate({pathname:`/error${search}`}); 
        } 
    },[navigate,props.urlParams,search])

    //display placeholder icon for pdfs
   


    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        getDefendantName()
        if(!props.urlParams.caseId || !props.urlParams.defendant){
            navigate({pathname:`/error${search}`}); 
        }
        if (!state) {
            setPreview(undefined)
        }else{
            if(state.type !== 'application/pdf'){
                // free memory when ever this component is unmounted
                const objectUrl = URL.createObjectURL(state)
                setPreview(objectUrl)
                return () => URL.revokeObjectURL(objectUrl)   
            }else{
                //display icon for pdf here
            }
         
        }

        
    }, [state,getDefendantName,navigate,props,search])

    
          


    const handleChange = (e) => {
        let file = e.target.files[0];

        if(file.type === 'application/pdf'){
            URL.revokeObjectURL(state);
            setState(file);  
            setIsPdf(true);
        }else{
            setState(file);
            setIsPdf(false);
        }  
        if(file.type === 'application/pdf' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' ){
            setwrongFile(false);
        }else{
            setwrongFile(true);
            setState(null);
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    
    const submit = async () => {
        const encoded = await toBase64(state)   

        await fetch("http://52.48.240.70:8113/", {
            method: "post",
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            'cr_number': props.urlParams["caseId"], //window.location.pathname.split('/')[1] location.search.slice(1).split("&")[0].split("=")[1]
            'data': encoded,
            'filetype': state.type,
            })
        }).then(response => {
            const promise = Promise.resolve(response.json());
            promise.then((val) => {
            console.log(val)
            if(val === "Success!"){
            props.setAuth(true);
            navigate({pathname:`/success${search}`});
            } else {
            navigate({pathname:`/error${search}`}); 
            }
            }).catch(error =>{
                console.log(error)
             })
        })
        
      }


	return(
        <div className="vh-100  d-flex container-fluid">
            <div className="row d-flex flex-grow-1 flex-column text-center justify-content-center align-items-center">
                <div className="col-12 col-sm-8 pt-5">
                    <h2>Proof of bank account: Please provide a copy of a bank statement, finance agreement or credit card statement for your {defendant} account</h2>
                </div>
                <div className="col-12 col-sm-8 pt-2">    
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <label htmlFor="file-upload"><p> Upload file</p></label>
                        <input id="file-upload" style={{display: "none"}} type="file" name="file" accept=".pdf, .png, .jpg, .jpeg, .webp;" onChange={(e) => handleChange(e)}/>
                        <label id="mobile-photo" htmlFor="photo-upload"><p>Take Photo</p></label>
                        <input id="photo-upload" style={{display: "none"}} type="file" name="file" accept=".png, .jpg, .jpeg, .webp" capture="camera" onChange={(e) => handleChange(e)}/>
                        {preview ? (<img width="scale" height="150" src={preview} alt="preview" />) : (<div></div>)}
                        {isPdf ? (<img width="scale" height="150" src="/blank-page.png" alt="preview"/>):(<div></div>)}
                        {state ?(<p>{state.name}</p>): <div></div>}
                        {wrongFile? (<h3>please only upload the following file types: pdf, jpeg, jpg, png, webp</h3>):(<div></div>)}
                   </div>
                </div>
                <div className="col-12 col-sm-8 ios-click" style={{display: state ? 'block' : 'none'}}>
                    <button disabled={!state} onClick={submit} >Submit</button>
                </div>
            </div>
        </div>
    )
}

export default AccountProof;