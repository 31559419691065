import React from 'react'
import { useNavigate } from 'react-router-dom'
import './App.css'

function Errorpage() {
    
const navigate = useNavigate();

const goBack = () => {
    navigate(-1);
}

  return (
    <div className='vh-100 flex-column d-flex container'>
        <div className='d-flex flex-grow-1 text-center justify-content-center align-items-center'>
            <div className='row'>
                <h1>Something went wrong.</h1>
                <h2>Please try again.</h2>
                <button className="back-button" onClick={goBack}>Go Back</button>
            </div>
        </div>
    </div>
  )
}

export default Errorpage