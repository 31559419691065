import React from 'react'
import './App.css'

function Success() {


  return (
    <div className='vh-100 flex-column d-flex container'>
      <div className='d-flex flex-grow-1 text-center justify-content-center align-items-center'>
        <div className='row thank-you'>
          <h1>Submission Successful!</h1>
          <div className='d-flex justify-content-center'>
          <i className='fa-regular fa-circle-check fa-10x'/>
          </div>
          <h2>Thank you for providing your signature.</h2>
        </div>
      </div>
    </div>
  )
}

export default Success;