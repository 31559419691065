import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Errorpage from './Errorpage';
import AccountProof from './Forms/AccountProof';
import Signature from './Forms/Signature';
import Success from './Success';


 function Main(props) {

  return (
    <Routes>
        <Route exact path='*' element={<AccountProof urlParams={props.urlParams} setAuth={props.setAuth}/>}></Route>
        <Route exact path='/' element={<Signature urlParams={props.urlParams} setAuth={props.setAuth} />}></Route>
        <Route exact path='/success' element={props.auth ?<Success authorised={true}/>:<Navigate from="/success" to="/" />}> </Route>
        <Route exaxt path='/error' element={<Errorpage />} ></Route>
    </Routes>

  )
}
export default Main;