import React from 'react'

function Footer(props) {

    const defendant = props.urlParams["defendant"]


    function CopyRight(){
        return<>
          <div className='d-flex justify-content-center'>
            <div className="footer-width">
              <p>Your Claim Matters is a claims management company and is a trading style of Victor Hydon Ltd, Swinford House,
                Albion St, Brierley Hill DY5 3EE and is registered in England and Wales (Company No 09086740) VAT registration
                number 204 1226 68. Victor Hydon Ltd is regulated by the Financial Conduct Authority (Firm reference number:
                833758). These details can be confirmed by visiting the Financial Services Register, www.fca.org.uk/register.
                Tax rebate claims are not regulated by the Financial Conduct Authority. ICO Reg No. ZA067879.</p>

                { defendant === 'barclays' && <><br /><p>*SSB Law is a trading style of SSB Group Ltd, a firm of solicitors specialising in financial
                     mis-selling. SSB Group Ltd are registered in England and Wales under registration number 11620680.
                      Registered Office: Navigation House, 1 South Quay Drive, Sheffield, S2 5SU.  VAT Registration number 
                      322392130. Authorised and regulated by the Solicitors Regulation Authority (Number 654321) and subject to 
                      the Solicitors Code of Conduct at www.sra.org.uk/solicitors/code-of-conduct.</p> </>}
            </div>
          </div>
        </>
      }

  return (
    <div className='footer'>
        <div className='d-flex justify-content-center p-3'>
           { defendant !== 'barclays' && <div className='details row g-0 col-lg-12'>
                <div className='col-6 col-lg-3 mb-5 p-3'>
                    <h4><i className="fa fa-map-marker" style={{paddingRight: '10px'}}></i>Address Details</h4>
                    <hr />
                    <p><b>Your Claim Matters</b></p>
                    <p>84 Salop Street</p>
                    <p>Wolverhampton WV3 0SR</p>
                    <div className='d-flex justify-content-sm-start justify-content-around socials mt-4'>
                        <a href="https://www.facebook.com/Your-Claim-Matters-100689679171882" target='_blank' rel='noreferrer'>
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://uk.linkedin.com/company/your-claim-matters" target='_blank' rel='noreferrer'>
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="mailto:info@yourclaimmatters.co.uk">  
                            <i className="fa fa-envelope"></i>
                        </a>
                        <a href="tel:01902939000">
                            <i className="fa fa-phone"></i>
                        </a>
                    </div>
                </div>
                <div className='col-6 col-lg-3 mb-5 p-3'>
                    <h4><i className="fa fa-building" style={{paddingRight: '10px'}}></i>Services</h4>
                    <hr />
                    <ul>
                    <li>
                        <a href="https://mt.yourclaimmatters.co.uk/" target='_blank' rel='noreferrer'>Marriage Tax</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/plevin-ppi-claims/" target='_blank' rel='noreferrer'>Plevin PPI</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/ppi-tax-rebate/" target='_blank' rel='noreferrer'>PPI Tax Rebate</a>
                    </li>
                    </ul>
                </div>
                <div className='col-6 col-lg-3 p-3'>
                    <h4><i className="fa fa-bars" style={{paddingRight: '10px'}}></i>Navigation</h4>
                    <hr />
                    <ul>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/faq/" target='_blank' rel='noreferrer'>FAQs</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/about/" target='_blank' rel='noreferrer'>About Us</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/contact/" target='_blank' rel='noreferrer'>Contact Us</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/careers/" target='_blank' rel='noreferrer'>Careers</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/news/" target='_blank' rel='noreferrer'>News</a>
                    </li>
                    </ul>
                </div>
                <div className='col-6 col-lg-3 p-3'>
                    <h4><i className="fa fa-bars" style={{paddingRight: '10px'}}></i>Policies</h4>
                    <hr />
                    <ul>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/terms-and-conditions/" target='_blank' rel='noreferrer'>Terms and Conditions</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/privacy-policy/" target='_blank' rel='noreferrer'>Privacy Policy</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/cookie-policy/" target='_blank' rel='noreferrer'>Cookie Policy</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/complaints-procedure/" target='_blank' rel='noreferrer'>Complaints Procedure</a>
                    </li>
                    <li>
                        <a href="https://yourclaimmatters.co.uk/terms-of-use/" target='_blank' rel='noreferrer'>Terms of Use</a>
                    </li>
                    </ul>
                </div>
            </div>}
        </div>
        <div className='copy-right'>
            <CopyRight />
        </div>
    </div>
  )
}

export default Footer