import './App.css';
import Main from './Main';
import { useState } from 'react';

function App(props) {

  const [auth,setAuth] = useState(false);
  

  return (
    <Main auth={auth} urlParams={props.urlParams} setAuth={setAuth}/>
  )
}

export default App;