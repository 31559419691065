import { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import SignatureCanvas from 'react-signature-canvas'
import Popup from "reactjs-popup";
//import axios from "axios"
import { useEffect } from "react";
import barclaysImage from "./Background/MicrosoftTeams-image.png"
//import Terms from "./terms";
//import Agreement from "./Agreement";


function Signature(props) {

    //setting the signature to a state
    const [signature, setSignature] = useState(false);
    const sigCanvas = useRef({});
    
    //set T's & C's Agreement on change validation
    //const [agree, setAgree] = useState(true);  

    //preserve query parameters on submission
    const {search} = useLocation();

    //navigate to success on submission
    const navigate = useNavigate();

    //url parameters
    const firstname = props.urlParams["firstname"]
    const lastname = props.urlParams["lastname"]
    const caseid = props.urlParams["caseid"]
    const clientid = props.urlParams["clientid"]
    const defendant = props.urlParams["defendant"]

    useEffect(() =>{
        if(defendant === 'barclays'){
        document.documentElement.style.setProperty(`--background-image`, `url(${barclaysImage})`)
        document.documentElement.style.setProperty('--button-color', '#429cd8')
        document.documentElement.style.setProperty(`--font-color`, `--altfont-color`)
        }
    },[defendant])

    const Intro = () =>{
        if(firstname && lastname !== null){
            return <h1>Hello {firstname} {lastname},</h1>
        }else{
            return <div></div>
        }
    }
    const myRef = useRef(null);
    const scroll = () => {
      myRef.current?.scrollIntoView({behavior: 'smooth', block: 'end' });
    };

    //Save signature as svg and base64 encoded
    const save = () =>
    setSignature(sigCanvas.current.toDataURL('image/svg+xml'));
    scroll();
    
    
    //clear signature and signature preview
    const clear = () => {
        sigCanvas.current.clear();
        setSignature(false);
    }
    
   /* const submit = async() =>{
        await axios.post('http://localhost:8000/api', {
            clientid: clientid,
            caseid: caseid,
            signature: signature
        }).then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    }*/

    const pythonSubmit = async () =>{
        await fetch("/1.0/signature", {
            method: "post",
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            'cr_number': caseid, 
            'pn_number': clientid, 
            'data': signature,
            })
        }).then(response => {
            const promise = Promise.resolve(response.json());
            promise.then ((val) => {
                if(val === "Success!"){
                    props.setAuth(true);
                    navigate({pathname:`/success`,
                                search: search});
                    } else {
                    navigate({pathname:`/error`,
                                search: search}); 
                    }
            }).catch(error => {
                console.log(error)
            })
        })
      
    }
        
  return (
    <>
    <div className='intro container'>
        <div className='col-12 d-flex flex-grow-1 text-center justify-content-center align-items-center mt-5'>
            <div className='row d-flex text-center justify-content-center align-items-center mt-5 sigpreview'>
                <Intro />
                <h1>please provide your signature below.</h1>
                { defendant === 'barclays' && <p>I wish to confirm that my late partner &amp; I requested your services to investigate any potential Plevin/PPI
                     claim in respect to any finances taken with Barclays PLC. I also confirm that we had previously signed a letter
                      of authority giving our authorisation for you to act on our behalf, and that I still wish for you to act as my
                       representative in all dealing with Barclays PLC in regard to this matter.</p>}

                <div className="col-12 col-sm-8 signature">
                    {signature ? (<img src={signature} alt="signature"/>):(<div></div>)}
                    {/*<Agreement urlParams={props.urlParams}  setAgree={setAgree}/>*/}
                    <Popup modal trigger={signature ? <button style={{width: '50%', height: 'auto', fontSize: '0.8rem'}}>Amend signature</button> : <button>Sign Here</button>} closeOnDocumentClick={false}>
                        {close =>(
                            <>  <div className="row d-flex  text-center justify-content-center align-items-center">
                                    <button className="close" onClick={close}>X</button>
                                </div>
                                <div className="row d-flex text-center justify-content-center align-items-center">
                                    <SignatureCanvas ref={sigCanvas} canvasProps={{ className: "signatureCanvas" }} />
                                </div>
                                <div className="row d-flex  text-center justify-content-evenly align-items-center ">
                                    <button className="save" onClick={() =>{save(); close();}}>save</button>
                                    <button className="clear" onClick={clear}>Clear</button>
                                </div>
                            </>
                        )}
                    </Popup>
                    
                    {/*<Terms urlParams={props.urlParams} />*/}
                </div>
                <button 
                    ref={myRef} 
                    className='submit' 
                    disabled={signature === false} 
                    onClick={pythonSubmit} 
                    style={{display: signature ? 'initial':'none', backgroundColor: defendant === 'barclays' && '#429cd8'}}>
                    Submit
                </button>
            </div>
        </div>
    </div>
    </>
  )
}

export default Signature