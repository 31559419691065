import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Header from './Header/Header';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import Footer from './Footer';

const paths = new URLSearchParams(window.location.search);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Header urlParams={{'brand':paths.get("b"), 'defendant':paths.get("d")}}/>
      <App urlParams={{'defendant':paths.get("d"), 'caseid':paths.get("c"), 'clientid':paths.get("p"), 'product':paths.get("product"),
      'firstname':paths.get("f"), 'lastname':paths.get("l"),'brand':paths.get("b") }}/>
      <Footer urlParams={{'defendant':paths.get("d")}}/>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
